<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="1200"
      persistent
      @click:outside="dialog = false"
    >
      <v-card>
        <v-card-title
          class="pa-0"
        >
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title v-text="$t('product-packages.main-title')" />
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                icon
                dark
                @click="dialog = false"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-card-text
          class="d-flex justify-space-between text-align-center my-5"
        >
          <ProductPackages :company="company" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-alert
      type="info"
      icon="mdi-swap-horizontal"
      color="primary"
      outlined
      class="mt-8"
    >
      <span class="font-weight-bold black--text">
        {{ $t('settings.delete-account.step-downgrade.title') }}
        <br></span>

      <div class="black--text">
        {{ $t('settings.delete-account.step-downgrade.body-texts.0', { readablePlatformName }) }} <br>
        {{ $t('settings.delete-account.step-downgrade.body-texts.1') }}<br>
        <span
          :class="isPlatformMarkero ? 'd-block mb-1 font-weight-bold' : ''"
        >
          {{ $t('settings.delete-account.step-downgrade.body-texts.2') }}</span><br>
        {{ $t('settings.delete-account.step-downgrade.body-texts.3') }}

        <ul
          v-if="isPlatformMarkero"
          class="no-bullets"
        >
          <li>{{ $t('settings.delete-account.step-downgrade.list.0') }}</li>
          <li>{{ $t('settings.delete-account.step-downgrade.list.1') }}</li>
        </ul>
      </div>

      <div class="d-flex justify-end">
        <v-btn
          color="primary"
          text
          @click="dialog = true"
        >
          <v-icon
            class="mr-2"
          >
            mdi-swap-horizontal
          </v-icon>
          {{ $t('buttons.step-downgrade.label') }}
        </v-btn>
      </div>
    </v-alert>
  </div>
</template>

<script>
import brandingMixin from '@/mixins/branding'
import ProductPackages from '@/modules/productPackages/index.vue'

export default {
  components: { ProductPackages },
  mixins: [brandingMixin],
  props: {
    company: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      dialog: false
    }
  }
}
</script>
<style scoped>
.no-bullets {
  list-style-type: none;
  padding-left: 0;
}
</style>
