<template>
  <SettingsCard
    :title="$t('settings.payment.payment-method.title')"
    :subtitle="$t('settings.payment.payment-method.subtitle')"
    icon="mdi-cash-check"
    :is-default-open="true"
  >
    <div class="pa-4">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <span class="font-weight-bold">
              {{ $t('settings.payment.payment-method.body-texts.0') }}<br>
            </span>
            <span class="grey--text">
              {{ $t('settings.payment.payment-method.body-texts.1') }}<br>
              {{ $t('settings.payment.payment-method.body-texts.2') }}
            </span>
            <v-img
              :src="require('@/../public/img/billwerk-plus-logo.png')"
              alt="billwerk+ reepay logo"
              width="130"
              contain
              class="mt-2"
            />
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div
            v-if="paymentMethod"
            class="justify-center d-flex mb-sm-0"
          >
            <v-select
              v-model="paymentMethod"
              :items="paymentMethodItems"
              :label="$t('settings.payment.payment-method.labels.type')"
              :disabled="isChangeDisabled"
              required
              outlined
              :hint="$t('settings.payment.payment-method.labels.hint')"
              :persistent-hint="isChangeDisabled"
              @click="onClick('Choose payment method', paymentMethod)"
            />
          </div>
          <v-alert
            v-if="showRedirectMessage"
            outlined
          >
            <div class="justify-center d-flex flex-column align-center">
              <div class="justify-center d-flex align-center">
                <v-icon
                  class="mr-2"
                  color="success"
                >
                  mdi-shield-check
                </v-icon>
                <span>
                  <b>{{ $t('alerts.settings.payment-method.title') }}</b>
                </span>
              </div>
              <p>{{ $t('alerts.settings.payment-method.redirect') }}</p>
              <v-progress-circular
                color="primary"
                width="3"
                size="20"
                indeterminate
              />
            </div>
          </v-alert>
          <v-alert
            v-if="!showRedirectMessage && (isDebit || isCreditCard || isInvoice)"
            type="info"
          >
            <div v-if="isCreditCard">
              <v-row>
                <v-col>
                  {{ $t('alerts.settings.payment-method.credit-card-label') }}:
                </v-col>
                <v-col> <b>{{ paymentInfo.cardType }}</b></v-col>
              </v-row>
              <v-row>
                <v-col>{{ $t('alerts.settings.payment-method.credit-card-info') }}: </v-col>
                <v-col> <b>{{ paymentInfo.maskedCard }}</b></v-col>
              </v-row>
              <v-row>
                <v-col>{{ $t('alerts.settings.payment-method.user') }}:</v-col>
                <v-col> <b>{{ paymentInfo.name }}</b></v-col>
              </v-row>
            </div>
            <div v-if="isDebit">
              <v-row>
                <v-col>
                  {{ $t('alerts.settings.payment-method.debit-label') }}:
                </v-col>
                <v-col> <b>{{ formatDate(paymentInfo.signatureDate,'DATE_LONG') }}</b></v-col>
              </v-row>
              <v-row>
                <v-col>{{ $t('alerts.settings.payment-method.debit-info') }}: </v-col>
                <v-col> <b>{{ paymentInfo.IBAN }}</b></v-col>
              </v-row>
              <v-row>
                <v-col>{{ $t('alerts.settings.payment-method.user') }}:</v-col>
                <v-col> <b>{{ paymentInfo.name }}</b></v-col>
              </v-row>
            </div>
            <div v-if="isInvoice">
              <p>{{ $t('labels.our-tip') }}:</p>
              <p>{{ $t('alerts.settings.payment-method.hint') }}:</p>
              <ul>
                <li
                  v-for="key in Object.keys(paymentMethodLabels)"
                  :key="key"
                >
                  {{ $t(paymentMethodLabels[key]) }}
                </li>
              </ul>
            </div>
          </v-alert>
          <div
            v-if="!isInvoice && !isNone"
            class="justify-end d-flex text-uppercase"
          >
            <a @click="startPayment">{{ $t('settings.payment.payment-method.labels.update', { method: $t(paymentMethodLabels[paymentMethod])}) }}</a>
          </div>
        </v-col>
      </v-row>
    </div>
  </SettingsCard>
</template>

<script>
import bus, { eventNames } from '@/lib/eventBus'
import SettingsCard from '@/components/SettingsCard.vue'
import brandingMixin from '@/mixins/branding'
import featureMixin from '@/mixins/feature'
import billwerkPortalMixin from '@/mixins/billwerkPortal'

const paymentMethodLabels = {
  'CreditCard:Reepay': 'settings.payment.payment-method.methods.credit-card',
  'ApplePay:Reepay': 'settings.payment.payment-method.methods.apple-pay',
  'Debit:Reepay': 'settings.payment.payment-method.methods.debit'
}

const FALLBACK_PAYMENT_METHOD = 'CreditCard:Reepay'

const PAYMENT_CALLBACK_PARAM = 'paymentCallback'

export default {
  components: { SettingsCard },
  mixins: [brandingMixin, featureMixin, billwerkPortalMixin],
  data: function () {
    return {
      paymentMethodLabels,
      showRedirectMessage: false,
      state: false,
      responseTimeout: null
    }
  },
  computed: {
    isChangeDisabled () {
      return this.paymentMethodItems.length <= 1
    },
    paymentMethods () {
      const { config } = this.$features.feature(this.featureNames.BILLWERK)
      return config.paymentMethods || [FALLBACK_PAYMENT_METHOD]
    },
    paymentMethodItems () {
      return this.paymentMethods
        .filter((method) => {
          // Show Apple Pay only if supported
          return method !== 'ApplePay:Reepay' || window.ApplePaySession
        })
        .map((method) => ({
          text: this.$t(paymentMethodLabels[method]),
          value: method
        }))
        .concat(this.isInvoice ? {
          text: this.$t('settings.payment.payment-method.methods.invoice'),
          value: 'BlackLabel:InvoicePayment',
          disabled: true
        } : [])
        .concat(this.isNone ? {
          text: this.$t('settings.payment.payment-method.methods.none'),
          value: 'none:none',
          disabled: true
        } : [])
    }
  },

  watch: {
    isSubscriptionJsInitialized (isInitialized) {
      if (isInitialized && this.state === 'finalize') {
        this.finalize()
      }
    },
    paymentMethod: {
      handler (newVal, oldVal) {
        if (newVal != null && oldVal !== null && newVal !== oldVal) {
          this.startPayment()
        }
      }
    }
  },

  created () {
    if (this.isCallbackAfterPayment()) {
      this.state = 'finalize'
    }
  },

  mounted () {
    window.scrollTo(0, 0)
  },

  unmounted () {
    clearTimeout(this.responseTimeout)
  },

  methods: {
    onClick (label, value) {
      this.$tracking.event('Settings', 'Clicked', label, value)
    },
    isCallbackAfterPayment () {
      return this.$route.query[PAYMENT_CALLBACK_PARAM]
    },
    startPayment () {
      this.$tracking.event('Settings', 'Edited', 'Start Payment Process')
      this.showRedirectMessage = true
      setTimeout(() => {
        this.startPaymentProcess()
      }, 3000)
    },
    composePaymentRedirectUrl () {
      const currentPath = this.$route.path
      const url = new URL(currentPath, window.location.origin)
      url.searchParams.set(PAYMENT_CALLBACK_PARAM, '1')
      return url.toString()
    },
    startPaymentProcess () {
      const paymentConfig = {
        publicApiKey: process.env.VUE_APP_BILLWERK_PUBLIC_API_KEY,
        providerReturnUrl: this.composePaymentRedirectUrl()
      }

      const paymentData = {
        bearer: this.paymentMethod
      }

      const onPaymentServiceReady = () => {
        this.portal.paymentChange(
          this.paymentService,
          paymentData,
          this.handlePaymentChangedSuccess,
          () => {
            bus.$emit(
              eventNames.SHOW_SNACKBAR,
              { color: 'error', text: this.$t('alerts.settings.payment-method.error') }
            )
            this.paymentMethod = null
            this.showRedirectMessage = false
            this.getContractDetails()
            this.$router.replace(this.$route.name)
          }
        )
      }

      const onError = (error) => {
        // eslint-disable-next-line no-console
        console.log('Payment service init error', error)
      }

      this.paymentService = new this.SubscriptionJS.Payment(
        paymentConfig,
        onPaymentServiceReady,
        onError
      )
    },
    handlePaymentChangedSuccess (data) {
      if (data.Url) {
        window.location.href = data.Url
      } else {
        this.$tracking.event('Settings', 'Selected', 'Payment Method Added')
      }
    },
    finalize () {
      this.SubscriptionJS.finalize(
        this.handleFinalizeSuccess,
        this.handleFinalizeError
      )
    },

    handleFinalizeSuccess () {
      this.paymentMethod = null
      this.getContractDetails()
      this.$router.replace(this.$route.path)
    },

    handleFinalizeError (error) {
      this.responseTimeout = setTimeout(() => {
        if (error.errorCode.includes('Canceled')) {
          // We want all query params to be removed in the URL
          this.$router.replace(this.$route.name)
        } else {
          bus.$emit(
            eventNames.SHOW_SNACKBAR,
            { color: 'error', text: this.$t('alerts.settings.payment-method.error') }
          )
        }
      }, 3000)
    }
  }
}
</script>

<style scoped>

.v-input--selection-controls {
  margin-top: 0;
  max-height: 30px;
}
</style>
