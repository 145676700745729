<template>
  <div>
    <div class="d-flex justify-space-between flex-md-row flex-column">
      <v-dialog
        v-model="isMarkeroUpgradeDialogOpen"
        max-width="1200"
        persistent
        @click:outside="isMarkeroUpgradeDialogOpen = false"
      >
        <v-card>
          <v-card-title
            class="pa-0"
          >
            <v-toolbar
              color="primary"
              dark
              flat
            >
              <v-toolbar-title v-text="$t('product-packages.main-title')" />
              <v-spacer />
              <v-toolbar-items>
                <v-btn
                  icon
                  dark
                  @click="isMarkeroUpgradeDialogOpen = false"
                >
                  <v-icon large>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
          </v-card-title>
          <v-card-text
            class="d-flex justify-space-between text-align-center my-5"
          >
            <ProductPackages :company="company" />
          </v-card-text>
        </v-card>
      </v-dialog>
      <PackageLogo :current-package="currentPackage" />
      <Permission permission="subscription:change">
        <Feature :feature-slug="featureNames.CHANGE_SUBSCRIPTION">
          <v-btn
            v-if="!isExpert"
            color="primary"
            :class="{'w-full mt-4':$vuetify.breakpoint.smAndDown}"
            :disabled="disabled"
            @click="changeSubscription"
          >
            <v-icon>mdi-swap-horizontal</v-icon>
            {{ $t('buttons.package.cta') }}
          </v-btn>
        </Feature>
      </Permission>
    </div>

    <ChangeSubscription
      :dialog="isUpgradeDialogOpen"
      :current-package="currentPackage"
      :company="company"
      :hide-downgrade="true"
      @close="isUpgradeDialogOpen = false"
    />
  </div>
</template>

<script>
import { ProductType } from '@/lib/productTypes'
import ChangeSubscription from '../ChangeSubscription'
import PackageLogo from '@/components/PackageLogo'
import featureMixin from '@/mixins/feature'
import Permission from '@/components/Permission'
import brandingMixin from '@/mixins/branding'
import ProductPackages from '@/modules/productPackages/index.vue'

export default {
  components: {
    ProductPackages,
    ChangeSubscription,
    PackageLogo,
    Permission
  },
  mixins: [featureMixin, brandingMixin],
  props: {
    company: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isUpgradeDialogOpen: false,
      isMarkeroUpgradeDialogOpen: false

    }
  },
  computed: {
    isExpert () {
      return this.company?.productType === ProductType.EXPERT
    },
    currentPackage () {
      return this.company?.productType
    }
  },
  methods: {
    changeSubscription () {
      this.$tracking.event('Plan', 'Clicked', 'Change Subscription')

      if (this.isPlatformMarkero) {
        this.isMarkeroUpgradeDialogOpen = true
        return
      }

      this.isUpgradeDialogOpen = true
    }
  }

}
</script>
